import React, { useMemo, useCallback } from 'react';
import { pipe, props, propOr, __ } from 'ramda';
import PropTypes from 'prop-types';

import { TITLE, FIRST_NAME, LAST_NAME, EMAIL, ID } from 'store/session';
import { POSITION_MAP } from 'utils/constants';

import { ReactComponent as CrossIcon } from 'assets/svg-icons/cross.svg';
import { Container, Wrapper, Text, Button } from './styles';

const SelectedNurse = ({ item, onDelete, disabled }) => {
  const [name, email] = useMemo(
    () => [
      [pipe(propOr('', TITLE), propOr('', __, POSITION_MAP))(item), ...props([FIRST_NAME, LAST_NAME], item)]
        .filter(Boolean)
        .join(' '),
      item[EMAIL],
    ],
    [item]
  );
  const handleDelete = useCallback(() => onDelete(item), [onDelete, item]);

  return (
    <Container>
      <Wrapper>
        {Boolean(name) && <Text>{name}</Text>}
        {Boolean(email) && <Text>{email}</Text>}
      </Wrapper>
      {!disabled && (
        <Button type="button" onClick={handleDelete}>
          <CrossIcon />
        </Button>
      )}
    </Container>
  );
};

SelectedNurse.defaultProps = { disabled: false };
SelectedNurse.propTypes = {
  item: PropTypes.shape({
    [ID]: PropTypes.string.isRequired,
    [TITLE]: PropTypes.string,
    [FIRST_NAME]: PropTypes.string,
    [LAST_NAME]: PropTypes.string,
    [EMAIL]: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default SelectedNurse;
