import { object, number, array, date, ref } from 'yup';
import { isEmpty, times, head, pipe, props, filter, join } from 'ramda';

import { DAYS, SHIFTS, REGISTRATION_DATE } from 'store/planning';
import { FIRST_NAME, LAST_NAME } from 'store/session';

const validationSchema = object({
  [DAYS]: number()
    .min(1, 'Mindestens 1')
    .max(10, 'Maximal 10')
    .required('Bitte wählen Sie die Anzahl der Tage')
    .typeError('Bitte wählen Sie die Anzahl der Tage'),
  [SHIFTS]: array()
    .of(
      array().of(
        date().min(
          new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
          'Die Messtage dürfen nicht in der Vergangenheit liegen. Bitte prüfen Sie die Daten.'
        )
      )
    )
    .required('Shifts are required'),
  [REGISTRATION_DATE]: date()
    .max(ref(`${SHIFTS}.0.0`), 'Der Anmeldeschluss darf nicht nach dem ersten Messtag liegen.')
    .required('Bitte wählen Sie das Registrierungsdatum'),
});

export const validateFrom = async (values, action) => {
  try {
    await validationSchema.validate(values, { abortEarly: false });
    action(($) => (isEmpty($) ? $ : {}));
  } catch (yupErrors) {
    action(
      yupErrors.inner.reduce((acc, { path, message }) => {
        acc[path.includes(SHIFTS) ? SHIFTS : path] = message;

        return acc;
      }, {})
    );
  }
};

export const getHoursAndMinutes = (dateStr) => {
  const d = new Date(dateStr);

  return [d.getHours(), d.getMinutes()].map((v) => (v < 10 ? `0${v}` : v)).join(':');
};

export const resetShifts = (dateStr, shifts, days) => {
  const lastDate = new Date(dateStr);

  return times((i) => {
    if (i !== 0) {
      const day = lastDate.getDay();

      lastDate.setDate(lastDate.getDate() + ((day === 5 && 3) || (day === 6 && 2) || 1));
    }

    if (shifts[i]) {
      lastDate.setHours(new Date(shifts[i][0]).getHours());
    } else {
      lastDate.setHours(i === 0 ? 10 : 9);
    }

    const result = [lastDate.toISOString()];

    lastDate.setHours(shifts[i] ? new Date(shifts[i][1]).getHours() : 17);
    result.push(lastDate.toISOString());

    return result;
  }, days || shifts.length);
};

export const getDateFromShifts = (shifts = []) => {
  const firstDay = head(head(shifts) || []);
  const d = firstDay ? new Date(firstDay) : new Date();

  return new Date(d.getFullYear(), d.getMonth(), d.getDate()).toISOString();
};

export const getRegistrationDate = (shifts = []) => {
  const firstDay = head(head(shifts) || []);
  const d = firstDay ? new Date(firstDay) : new Date();

  return new Date(d.getFullYear(), d.getMonth() - 1, 0).toISOString();
};

export const getNurseName = pipe(props([FIRST_NAME, LAST_NAME]), filter(Boolean), join(' '));
