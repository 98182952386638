import React, { useMemo, useCallback } from 'react';
import { prop, propEq } from 'ramda';
import PropTypes from 'prop-types';

import { useReportsActions, useAlerts, STATUS, REPORT_STATUS } from 'store/reports';
import Download from 'assets/svg-icons/Download';
import Spinner from 'components/FullScreenSpinner';
import Select from 'components/SelectNew';

import { Container, Id, NavLink, Field, DownloadWrapper } from './styles';

const Row = ({ id, widths, publicId, patientId, patientName, receiverName, date, status, url }) => {
  const { saveReport } = useReportsActions();
  const { extractId, loading } = useAlerts();

  const selectedOption = useMemo(() => REPORT_STATUS.find(propEq(`${status}`, 'id')), [status]);
  const handleSelect = useCallback(
    (statusId) => {
      if (id && statusId) {
        extractId(saveReport({ reportId: id, [STATUS]: Number(statusId) }));
      }
    },
    [extractId, id, saveReport]
  );

  const renderStatusLabel = useMemo(() => {
    if (loading) return <Spinner size={40} height="40px" />;

    const stat = String(status);

    if (stat === '2' || stat === '5' || stat === '6') {
      return prop('title', selectedOption);
    }

    const filteredStatuses = REPORT_STATUS.map(({ id: i, title }) => ({
      id: i,
      title,
      disabled:
        i === '0' ||
        i === '1' ||
        i === '5' ||
        ((stat === '0' || stat === '1') && i !== '2') ||
        (stat === '3' && i !== '2' && i !== '6') ||
        (stat === '4' && i !== '6'),
    }));

    return (
      <Select
        placeholder="Status"
        value={typeof status === 'number' ? String(status) : ''}
        options={filteredStatuses}
        labelKey="title"
        onSelect={handleSelect}
      />
    );
  }, [handleSelect, loading, selectedOption, status]);

  return (
    <Container key={publicId}>
      <Id width={widths[0]}>{publicId}</Id>
      {patientId ? (
        <NavLink to={`/patients/${patientId}`} width={widths[1]}>
          {patientName}
        </NavLink>
      ) : (
        <Field width={widths[1]}>{patientName}</Field>
      )}
      <Field width={widths[2]}>{receiverName}</Field>
      <Field width={widths[3]}>{date}</Field>
      <Field width={widths[4]}>{renderStatusLabel}</Field>
      <DownloadWrapper width={widths[5]}>
        <a href={url} aria-label="Download" download target="_blank" rel="noopener noreferrer">
          <Download />
        </a>
      </DownloadWrapper>
    </Container>
  );
};

Row.defaultProps = {
  publicId: '',
  patientId: null,
  patientName: '',
  receiverName: '',
  date: '',
  url: '',
};

Row.propTypes = {
  id: PropTypes.string.isRequired,
  widths: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  publicId: PropTypes.string,
  patientId: PropTypes.string,
  patientName: PropTypes.string,
  receiverName: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  url: PropTypes.string,
};

export default Row;
