import React, { useState, useMemo } from 'react';
import { uniq } from 'ramda';

import { DATE } from 'store/planning';
import { parseDate } from 'utils';

import { Select } from './styles';

export const useFilter = (shifts, list, loading) => {
  const [filter, setFilter] = useState('all');
  const items = useMemo(() => {
    if (!filter || filter === 'all') return list;

    const d = new Date(filter);
    const range = [d.valueOf(), d.valueOf() + 86400000];

    return list.filter(({ [DATE]: date }) => {
      const val = new Date(date).valueOf();

      return val >= range[0] && val < range[1];
    });
  }, [filter, list]);
  const filterOptions = useMemo(() => {
    const values = shifts.map(([date]) => {
      const d = new Date(date);

      return new Date(d.getFullYear(), d.getMonth(), d.getDate()).toISOString();
    });

    return [
      { id: 'all', label: 'Alle Messtage' },
      ...uniq(values).map((id, i) => ({ id, label: `${i + 1}. Messtag vom ${parseDate(id)}` })),
    ];
  }, [shifts]);

  return [filter, items, <Select value={filter} options={filterOptions} onSelect={setFilter} disabled={loading} />];
};
