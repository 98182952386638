export const FEATURE_NAME = 'reports';

export const LIST = 'list';
export const FILTER = 'filter';

export const PUBLIC_ID = 'publicId';
export const BIRTH = 'birthday';
export const RECEIVER = 'receiver';
export const INSTITUTION = 'institution';
export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const REPORT_URL = 'url';

export const READ = 'read';
export const UNREAD = 'unread';
export const CUSTOM = 'custom';
export const EMPTY = 'empty';

export const REPORT_STATUS = [
  { id: '0', title: 'Wird gedruckt' },
  { id: '1', title: 'Wird gemailt' },
  { id: '5', title: 'In Verarbeitung' },
  { id: '2', title: 'Versendet' },
  { id: '3', title: 'Nicht abgeholt' },
  { id: '4', title: 'Retourniert' },
  { id: '6', title: 'Erneut zustellen' },
];
