import styled from 'styled-components';

import PageHeader from 'components/PageHeader';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 30px 0 10px;
`;

export const Header = styled(PageHeader)`
  margin-bottom: 20px;
`;

export const Status = styled.p`
  min-width: 120px;
  font-size: var(--font-size10);
  font-weight: var(--font-weight600);
  color: ${({ $color }) => $color || 'var(--color-grey6A)'};
  letter-spacing: 1.01px;
  text-transform: uppercase;
  text-align: right;
`;

export const Text = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
`;
