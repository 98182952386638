import React, { useState, useMemo, useCallback, useEffect, Fragment } from 'react';
import { times } from 'ramda';
import PropTypes from 'prop-types';

import { usePlanningActions, ID, DATE, DAYS, SHIFTS, REGISTRATION_DATE, SUB_TEAM_MEMBERS } from 'store/planning';
import { DAYS_OPTIONS, TIME_OPTIONS } from '../../consts';
import DateInput from '../../DateInput';

import { validateFrom, getHoursAndMinutes, resetShifts, getDateFromShifts, getRegistrationDate, getNurseName } from './utils';
import { useFormData } from './hooks';
import Downloads from './Downloads';
import SelectedNurse from './SelectedNurse';
import {
  Section,
  Container,
  Wrapper,
  Item,
  Label,
  Select,
  SmallText,
  Message,
  InputWrap,
  DropdownWrap,
  DropdownLabel,
  Dropdown,
} from './styles';

const Form = ({ readMode, disabled, errors, setErrors, setDateError, setRegistrationDateError, hasRegistrations }) => {
  const { setScheduleUpdates, fetchCollectNurses } = usePlanningActions();

  const { id, values } = useFormData();
  const [index, setIndex] = useState(0);
  const date = values[DATE];
  const days = values[DAYS];
  const shifts = values[SHIFTS];
  const registrationDate = values[REGISTRATION_DATE];
  const indexes = useMemo(() => times((i) => ({ id: i, label: `${i + 1}. Tag` }), shifts.length), [shifts.length]);
  const [time1, time2] = useMemo(() => (shifts[index] ? shifts[index].map(getHoursAndMinutes) : []), [index, shifts]);

  const updateRegistrationDate = useCallback(
    (val) => setScheduleUpdates({ [ID]: id, [REGISTRATION_DATE]: val }),
    [id, setScheduleUpdates]
  );
  const updateDays = useCallback(
    (val) => {
      const value = Number(val);
      const items = resetShifts(date, shifts, value);

      setIndex(($) => (value - 1 < Number($) ? String(value - 1) : $));
      setScheduleUpdates({
        [ID]: id,
        [DATE]: getDateFromShifts(items),
        [DAYS]: value,
        [SHIFTS]: items,
        [REGISTRATION_DATE]: getRegistrationDate(items),
      });
    },
    [id, setScheduleUpdates, shifts, date]
  );
  const updateDayDate = useCallback(
    (val) => {
      const items = [...shifts];
      items[index] = shifts[index].map((v) => {
        const current = new Date(val);
        const d = new Date(v);

        current.setHours(d.getHours());
        current.setMinutes(d.getMinutes());

        return current.toISOString();
      });

      setScheduleUpdates({
        [ID]: id,
        [DATE]: getDateFromShifts(items),
        [SHIFTS]: items,
        [REGISTRATION_DATE]: getRegistrationDate(items),
      });
    },
    [id, index, setScheduleUpdates, shifts]
  );
  const updateTimeStart = useCallback(
    (time) => {
      const items = [...shifts];
      const [h, m] = time.split(':').map(Number);
      const d = new Date(items[index][0]);

      d.setHours(h);
      d.setMinutes(m);
      items[index][0] = d.toISOString();

      setScheduleUpdates({
        [ID]: id,
        [DATE]: getDateFromShifts(items),
        [SHIFTS]: items,
        [REGISTRATION_DATE]: getRegistrationDate(items),
      });
    },
    [id, index, setScheduleUpdates, shifts]
  );
  const updateTimeEnd = useCallback(
    (time) => {
      const items = [...shifts];
      const [h, m] = time.split(':').map(Number);
      const d = new Date(items[index][1]);

      d.setHours(h);
      d.setMinutes(m);
      items[index][1] = d.toISOString();

      setScheduleUpdates({
        [ID]: id,
        [DATE]: getDateFromShifts(items),
        [SHIFTS]: items,
        [REGISTRATION_DATE]: getRegistrationDate(items),
      });
    },
    [id, index, setScheduleUpdates, shifts]
  );
  const updateMembers = useCallback((val) => setScheduleUpdates({ [ID]: id, [SUB_TEAM_MEMBERS]: val }), [id, setScheduleUpdates]);

  useEffect(() => {
    validateFrom(values, setErrors);
  }, [setErrors, values]);

  return (
    <Section label="Übersicht">
      <Container>
        <Wrapper>
          <Item>
            <Label>Messtage</Label>
            <Select value={days} options={DAYS_OPTIONS} onSelect={updateDays} disabled={disabled} readMode={readMode} />
            {!(readMode || disabled) && errors[DAYS] && <Message>{errors[DAYS]}</Message>}
          </Item>
          <Item>
            <div />
            <InputWrap $error={errors[SHIFTS]}>
              <Select value={index} options={indexes} onSelect={setIndex} disabled={disabled} readMode={readMode} />
            </InputWrap>
          </Item>
          {shifts[index] && (
            <Fragment key={`${index}-${date}-${days}`}>
              <Item>
                <Label>Datum</Label>
                <DateInput
                  value={shifts[index][0]}
                  onChange={updateDayDate}
                  setValidationError={setDateError}
                  readMode={readMode}
                  disabled={disabled}
                />
              </Item>
              <Item>
                <Label>Uhrzeit</Label>
                <Select value={time1} options={TIME_OPTIONS} onSelect={updateTimeStart} disabled={disabled} readMode={readMode} />
                <SmallText>bis</SmallText>
                <Select value={time2} options={TIME_OPTIONS} onSelect={updateTimeEnd} disabled={disabled} readMode={readMode} />
              </Item>
              <Item>
                <Label>Anmeldeschluss</Label>
                <InputWrap $error={errors[REGISTRATION_DATE]}>
                  <DateInput
                    value={registrationDate}
                    onChange={updateRegistrationDate}
                    setValidationError={setRegistrationDateError}
                    readMode={readMode}
                    disabled={disabled}
                    allowAllDays
                  />
                </InputWrap>
              </Item>
            </Fragment>
          )}
          <DropdownWrap>
            <DropdownLabel>Messungen durch</DropdownLabel>
            <Dropdown
              idKey={ID}
              nameKey={getNurseName}
              action={fetchCollectNurses}
              placeholder="Mitarbeiter suchen"
              value={values[SUB_TEAM_MEMBERS]}
              onChange={updateMembers}
              selectedComponent={SelectedNurse}
              disabled={disabled}
            />
          </DropdownWrap>
        </Wrapper>
        <Downloads id={id} hasRegistrations={hasRegistrations} />
      </Container>
    </Section>
  );
};

Form.defaultProps = { disabled: false };
Form.propTypes = {
  readMode: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,
  setErrors: PropTypes.func.isRequired,
  setDateError: PropTypes.func.isRequired,
  setRegistrationDateError: PropTypes.func.isRequired,
  hasRegistrations: PropTypes.bool.isRequired,
};

export default Form;
