import React, { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { pathOr, pipe, props, filter, join, path } from 'ramda';
import PropTypes from 'prop-types';

import { useCurrentSchedule, SUB_ORGANIZATION, NAME, DATE, STATUS, META } from 'store/planning';
import { TITLE, LAST_NAME, FIRST_NAME, EDITOR, MODIFIED } from 'store/patients';
import { useUser } from 'store/session';
import { parseDate, parseDateWithTime } from 'utils';
import { POSITION_MAP } from 'utils/constants';
import { STATUSES } from '../consts';

import { Container, Header, Status, Text } from './styles';

const getName = pipe(($) => [POSITION_MAP[$[TITLE]], ...props([FIRST_NAME, LAST_NAME], $)], filter(Boolean), join(' '));
const FormHeader = ({ onBack }) => {
  const { push } = useHistory();
  const schedule = useCurrentSchedule();
  const user = useUser();
  const [title, statusData, editor, modified] = useMemo(
    () =>
      schedule
        ? [
            [pathOr('', [SUB_ORGANIZATION, NAME], schedule), parseDate(schedule[DATE])].filter(Boolean).join(', ') ||
              'Neuer Terminplan',
            STATUSES[schedule[STATUS]] || STATUSES[0],
            pipe(pathOr({}, [META, EDITOR]), getName)(schedule),
            pipe(path([META, MODIFIED]), parseDateWithTime)(schedule),
          ]
        : ['Neuer Terminplan', STATUSES[0], getName(user), parseDateWithTime(new Date())],
    [schedule, user]
  );
  const redirectToBack = useCallback(() => (typeof onBack === 'function' ? onBack() : push('/planning')), [onBack, push]);

  return (
    <Container>
      <Header title={title} redirectToBack={redirectToBack}>
        <Status $color={statusData.color}>{statusData.label}</Status>
      </Header>
      <Text>
        Zul. bearbeitet von {editor} am {modified}
      </Text>
    </Container>
  );
};

FormHeader.defaultProps = { onBack: null };
FormHeader.propTypes = { onBack: PropTypes.func };

export default FormHeader;
