import styled from 'styled-components';

import PaginationDropDown from 'components/PaginationDropDown';
import SelectDef from 'components/SelectNew';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 275px auto;
  gap: 25px 10px;
  width: 100%;
  margin-top: 20px;
`;

export const Label = styled.p`
  font-size: var(--font-size15);
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
  line-height: ${({ $isSmall }) => ($isSmall ? 26 : 40)}px;
`;

export const InputWrap = styled.div`
  justify-self: ${({ $fullWidth }) => ($fullWidth ? 'auto' : 'start')};
  display: flex;
  flex-direction: column;
  align-items: ${({ $fullWidth }) => ($fullWidth ? 'auto' : 'start')};
  &:after {
    content: ${({ $error }) => (typeof $error === 'string' ? `'${$error}'` : '')};
    color: var(--color-orangeDC);
    font-size: var(--font-size13);
  }
`;

export const Dropdown = styled(PaginationDropDown)`
  margin-top: 0;
`;

export const Select = styled(SelectDef).attrs(() => ({ mb: 0, mini: true }))`
  width: ${({ $autoWidth }) => ($autoWidth ? 'auto' : '100%')};
  margin-top: ${({ $mt }) => ($mt ? 14 : 0)}px;
`;

export const Wrapper = styled.div`
  justify-self: start;
  display: grid;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  gap: 15px;
  position: relative;
  padding-top: 14px;
  &:before {
    content: 'Uhrzeit';
    position: absolute;
    inset: -3px auto auto 0;
    font-size: var(--font-size10);
    font-weight: var(--font-weight500);
    color: var(--color-grey6A);
  }
`;

export const Text = styled.p`
  font-size: var(--font-size10);
  font-weight: var(--font-weight500);
  color: var(--color-grey6A);
`;

export const Message = styled.p`
  color: var(--color-orangeDC);
  font-size: var(--font-size13);
  word-break: break-word;
`;

export const MultiWrap = styled.div`
  display: grid;
  grid-template-columns: 90px auto 1fr;
  align-items: start;
  column-gap: 10px;
`;
