import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

import { usePlanningActions, useAlerts } from 'store/planning';
import Button from 'components/Button';
import Spinner from 'components/FullScreenSpinner';
import Message from 'components/Message';
import Download from 'assets/svg-icons/Download';
import FormHeader from '../../../FormHeader';
import Section from '../../../Section';

import { Wrapper, DownloadBtn, IconWrap } from './styles';

const Step2 = ({ setStep }) => {
  const { id } = useParams();
  const { getScheduleCoverLetters, getScheduleConsents } = usePlanningActions();
  const { action: getCoverLetters, loading: coverLettersLoading, error: coverLettersErr } = useAlerts(getScheduleCoverLetters);
  const { action: getConsents, loading: consentsLoading, error: consentsErr } = useAlerts(getScheduleConsents);
  const onDownloadCoverLetters = useCallback(() => getCoverLetters(id), [getCoverLetters, id]);
  const onDownloadConsents = useCallback(() => getConsents(id), [getConsents, id]);
  const onBack = useCallback(() => setStep(1), [setStep]);
  const onNext = useCallback(() => setStep(3), [setStep]);

  return (
    <>
      <FormHeader onBack={onBack} />
      <Section title="Begleitbrief und Einverständniserklärung" current={2} amount={3} withCounter />
      <Wrapper $mt={20}>
        <DownloadBtn type="button" onClick={onDownloadCoverLetters} disabled={coverLettersLoading}>
          {coverLettersLoading ? (
            <Spinner size={46} height="100%" />
          ) : (
            <>
              <span>Begleitbrief (DOCX)</span>
              <IconWrap>
                <Download />
              </IconWrap>
            </>
          )}
        </DownloadBtn>
        <DownloadBtn type="button" onClick={onDownloadConsents} disabled={consentsLoading}>
          {consentsLoading ? (
            <Spinner size={46} height="100%" />
          ) : (
            <>
              <span>Einverständniserklärung (DOCX)</span>
              <IconWrap>
                <Download />
              </IconWrap>
            </>
          )}
        </DownloadBtn>
      </Wrapper>
      {(coverLettersErr || consentsErr) && (
        <Message form={1} type="error">
          {coverLettersErr || consentsErr}
        </Message>
      )}
      <Wrapper $mt={50}>
        <Button type="button" onClick={onBack} height="40px" disabled={coverLettersLoading || consentsLoading}>
          Zurück
        </Button>
        <Button type="button" onClick={onNext} height="40px" color="normal" disabled={coverLettersLoading || consentsLoading}>
          Weiter
        </Button>
      </Wrapper>
    </>
  );
};

Step2.propTypes = {
  setStep: PropTypes.func.isRequired,
};

export default Step2;
