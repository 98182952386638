import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { P, TextInput, ErrorMessage } from './styles';

const Input = ({ field, label, error, readMode, isRed, ...rest }) => {
  const inputValue = useMemo(() => {
    const valString = field.value || field.value === 0 ? String(field.value) : '';

    if (readMode) return valString || '–';

    return valString;
  }, [field.value, readMode]);

  return (
    <div>
      <P>{label}</P>
      <TextInput
        name={field.name}
        value={inputValue}
        onChange={field.onChange}
        error={Boolean(error)}
        mb={0}
        readMode={readMode}
        $isRed={isRed}
        {...rest}
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </div>
  );
};

Input.defaultProps = {
  error: null,
  label: null,
  readMode: false,
  isRed: false,
};

Input.propTypes = {
  label: PropTypes.string,
  field: PropTypes.shape({
    name: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func.isRequired,
  }).isRequired,
  error: PropTypes.string,
  readMode: PropTypes.bool,
  isRed: PropTypes.bool,
};

export default Input;
