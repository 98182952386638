import styled from 'styled-components';

import DefSwitcher from 'components/Switcher';

export const Switcher = styled(DefSwitcher)`
  margin-right: 20px;
  & > p {
    text-align: center;
    min-width: 50px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 40px;
  background-color: ${({ error }) => (error ? '#fdd7d7' : 'var(--color-greyEC)')};
  border-radius: var(--border-radius8);
  padding: 0 5px;
  margin-right: 10px;
  box-sizing: border-box;
  & > p,
  & > svg {
    color: ${({ error }) => (error ? 'var(--color-red)' : 'var(--color-grey6A)')};
  }
`;

export const Text = styled.p`
  margin-right: 5px;
`;
