import React, { useCallback } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { NO_REPORT, NURSING_HOME } from 'store/patients';
import { SUB_ORGANIZATION, NO_PATIENT_REPORTS } from 'store/organizations';
import Checkbox from 'components/CheckboxNew';
import { WarnMessage } from '../styles';

const PatientReport = ({ readMode }) => {
  const [{ value }, , { setValue }] = useField(NO_REPORT);
  const [{ value: noPatientReports }] = useField(`${NURSING_HOME}.${SUB_ORGANIZATION}.${NO_PATIENT_REPORTS}`);

  const handleClick = useCallback(() => {
    if (!readMode) setValue(!value);
  }, [readMode, setValue, value]);

  return (
    <>
      <Checkbox label="Keinen Bericht an Patient senden" selected={value} onClick={handleClick} readMode={readMode} />
      {Boolean(noPatientReports) && (
        <WarnMessage type="error">
          Hinweis: Es wird kein Bericht an den Patienten gesendet, da dies im Pflegeheim entsprechend hinterlegt ist.
        </WarnMessage>
      )}
    </>
  );
};

PatientReport.propTypes = {
  readMode: PropTypes.bool.isRequired,
};

export default PatientReport;
