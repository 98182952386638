import styled from 'styled-components';

import arrow from './arrow.svg';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ label, labelWidth }) => (label ? `${labelWidth} 1fr` : '1fr')};
  column-gap: 40px;
  align-items: center;
  width: ${({ mini }) => (mini ? 'auto' : '100%')};
  margin-bottom: ${({ mb }) => mb}px;
`;

export const Label = styled.label`
  font-size: ${({ labelSize }) => labelSize};
  font-weight: var(--font-weight400);
  color: var(--color-grey6A);
`;

export const StyledSelect = styled.select`
  width: 100%;
  height: ${({ mini }) => (mini ? 26 : 40)}px;
  border: 1px solid;
  border-radius: ${({ mini }) => (mini ? 5 : 8)}px;
  border-color: ${({ readMode }) => (readMode ? 'var(--color-greyEC)' : 'var(--color-grey97)')};
  font-size: ${({ mini }) => (mini ? 'var(--font-size13)' : 'var(--font-size18)')};
  color: var(--color-grey6A);
  padding: 0 30px 0 10px;
  background-color: ${({ readMode }) => (readMode ? 'var(--color-greyEC)' : 'var(--color-white)')};
  background-image: ${({ readMode }) => (readMode ? 'none' : `url(${arrow})`)};
  background-position: right;
  background-repeat: no-repeat;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  &:disabled {
    opacity: 1;
    font-weight: var(--font-weight300);
    cursor: not-allowed;
  }
  &:focus {
    box-shadow: var(--shadow-input-focus);
  }
`;

export const Option = styled.option`
  &:disabled {
    font-weight: var(--font-weight300);
    color: var(--color-greyDC);
  }
`;
