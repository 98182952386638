import { pipe, prop, props, filter, join, trim, applySpec, path } from 'ramda';
import moment from 'moment';

import {
  ID,
  STATUS,
  PUBLIC_ID,
  SUB_PATIENT,
  RECEIVER,
  INSTITUTION,
  FIRST_NAME,
  LAST_NAME,
  META,
  CREATED,
  REPORT_URL,
  REPORT_STATUS,
  ALL,
} from 'store/reports';

export const statuses = [{ id: ALL, title: 'Alle' }, ...REPORT_STATUS];

export const tableHead = [
  {
    id: PUBLIC_ID,
    content: 'Bericht-Nr.',
    width: '15%',
  },
  {
    id: 'patientName',
    content: 'Patient',
    width: '22%',
    withoutSort: true,
  },
  {
    id: 'receiverName',
    content: 'Empfänger',
    width: '22%',
    withoutSort: true,
  },
  {
    id: 'meta.created',
    content: 'Datum',
    width: '10%',
  },
  {
    id: STATUS,
    content: 'Status',
    width: '21%',
  },
  {
    id: REPORT_URL,
    content: 'PDF',
    width: '10%',
    withoutSort: true,
  },
];

const joinName = (feature) => pipe(prop(feature), props([FIRST_NAME, LAST_NAME]), filter(Boolean), join(' '), trim);

const getReceiver = (report) => {
  const [institution = '', firstname = '', lastname = ''] = props([INSTITUTION, FIRST_NAME, LAST_NAME], prop(RECEIVER, report));

  if (institution) return institution;
  return [firstname, lastname].join(' ');
};

export const handleFields = applySpec({
  id: prop(ID),
  publicId: prop(PUBLIC_ID),
  patientId: path([SUB_PATIENT, ID]),
  patientName: joinName(SUB_PATIENT),
  receiverName: getReceiver,
  date: pipe(path([META, CREATED]), (date) => moment(date).format('DD.MM.YYYY')),
  status: prop(STATUS),
  url: prop(REPORT_URL),
});
