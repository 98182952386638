import qs from 'query-string';

import { wrapperRequest, getToken } from './utils';
import { API_URL, POST, PATCH, DELETE, OWN_ERR_HANDLER } from './consts';

export const createSchedule = (body = {}) =>
  wrapperRequest({
    url: `${API_URL}/schedules`,
    options: { method: POST, body, [OWN_ERR_HANDLER]: true },
  });

export const getSchedules = (query = {}) =>
  wrapperRequest({
    url: `${API_URL}/schedules?${qs.stringify(query)}`,
    options: { [OWN_ERR_HANDLER]: true },
  });

export const getSchedule = (id, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}?${qs.stringify(query)}`,
    options: { [OWN_ERR_HANDLER]: true },
  });

export const getScheduleUnsubscribers = (id) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}/unsubscribers`,
  });

export const saveSchedule = (id, body, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}?${qs.stringify(query)}`,
    options: { method: PATCH, body, [OWN_ERR_HANDLER]: true },
  });

export const saveRegistration = (id, regId, body, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}/registrations/${regId}?${qs.stringify(query)}`,
    options: { method: PATCH, body },
  });

export const removePatientSchedule = (scheduleId, registrationId, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${scheduleId}/registrations/${registrationId}?${qs.stringify(query)}`,
    options: { method: DELETE },
  });

export const createScheduleXML = (id, xmlFile, query = {}) => {
  const data = new FormData();
  data.append('file', xmlFile);

  return wrapperRequest({
    url: `${API_URL}/schedules/${id}/registrations/import?${qs.stringify(query)}`,
    options: {
      method: POST,
      headers: { AUTHORIZATION: `Bearer ${getToken()}` },
      file: data,
      [OWN_ERR_HANDLER]: true,
    },
  });
};

export const upgradeScheduleXML = (id, xmlFile, query = {}) => {
  const data = new FormData();
  data.append('file', xmlFile);

  return wrapperRequest({
    url: `${API_URL}/schedules/${id}/registrations/import?${qs.stringify(query)}`,
    options: {
      method: PATCH,
      headers: { AUTHORIZATION: `Bearer ${getToken()}` },
      file: data,
      [OWN_ERR_HANDLER]: true,
    },
  });
};

export const getScheduleForms = (id, query) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}/forms?${qs.stringify(query)}`,
  });

export const uploadScheduleForm = (id, pdfFile, ward) => {
  const data = new FormData();
  data.append('file', pdfFile);
  data.append('ward', ward);

  return wrapperRequest({
    url: `${API_URL}/schedules/${id}/forms`,
    options: {
      method: POST,
      headers: { AUTHORIZATION: `Bearer ${getToken()}` },
      file: data,
      [OWN_ERR_HANDLER]: true,
    },
  });
};

export const createScheduleForms = (id, query) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}/schedule?${qs.stringify(query)}`,
    options: {
      method: POST,
      headers: { AUTHORIZATION: `Bearer ${getToken()}` },
    },
  });

export const removeScheduleFormMedia = (scheduleId, mediaId) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${scheduleId}/forms/${mediaId}`,
    options: { method: DELETE },
  });

export const exportSchedule = (id) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}/export`,
    options: { method: POST, body: {} },
    downloadFile: true,
  });

export const getScheduleLabels = (id) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}/labels`,
    options: { method: POST, body: {} },
    downloadFile: true,
  });

export const getScheduleCoverLetters = (id) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}/coverletters`,
    options: {
      method: POST,
      body: {},
      headers: {
        AUTHORIZATION: `Bearer ${getToken()}`,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
    },
    downloadFile: true,
  });

export const getScheduleConsents = (id) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}/consents`,
    options: {
      method: POST,
      body: {},
      headers: {
        AUTHORIZATION: `Bearer ${getToken()}`,
        'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      },
    },
    downloadFile: true,
  });

export const createScheduleRegistration = (id, body, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${id}/registrations?${qs.stringify(query)}`,
    options: { method: POST, body },
  });

export const saveScheduleFormMedia = (scheduleId, mediaId, body = {}) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${scheduleId}/forms/${mediaId}`,
    options: { method: PATCH, body },
  });

export const reorderSchedule = (scheduleId, body, query = {}) =>
  wrapperRequest({
    url: `${API_URL}/schedules/${scheduleId}/reorder?${qs.stringify(query)}`,
    options: { method: POST, body },
  });
