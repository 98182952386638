import { object, string, number, array, date, ref } from 'yup';
import { head } from 'ramda';

import { SUB_ORGANIZATION, ID, DATE, DAYS, SHIFTS, REGISTRATION_DATE, SUB_TEAM_MEMBERS } from 'store/planning';

const getCorrectDate = (dateStr) => {
  const d = dateStr ? new Date(dateStr) : new Date();
  const day = d.getDay();

  return new Date(d.getFullYear(), d.getMonth(), d.getDate() + ((day === 6 && 2) || (day === 0 && 1) || 0)).toISOString();
};
const getDefaultShifts = (dateStr) => {
  const shift = [];
  const d = new Date(dateStr);

  d.setHours(10);
  d.setMinutes(0);
  shift.push(d.toISOString());
  d.setHours(17);
  shift.push(d.toISOString());

  return [shift];
};

export const getRegistrationDate = (shifts = []) => {
  const firstDay = head(head(shifts) || []);
  const firstDate = firstDay ? new Date(firstDay) : new Date();

  return new Date(firstDate.getFullYear(), firstDate.getMonth() - 1, 0).toISOString();
};

export const getInitialValues = (data) => {
  const scheduleDate = data[DATE] || getCorrectDate();
  const shifts = data[SHIFTS] || getDefaultShifts(scheduleDate);

  return {
    [SUB_ORGANIZATION]: data[SUB_ORGANIZATION] || null,
    [DATE]: scheduleDate,
    [DAYS]: data[DAYS] ?? 1,
    [SHIFTS]: shifts,
    [REGISTRATION_DATE]: getRegistrationDate(shifts),
    [SUB_TEAM_MEMBERS]: data[SUB_TEAM_MEMBERS] || [],
  };
};

export const shiftsValidation = array()
  .of(
    array().of(
      date().min(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), 'Some shift dates in the past')
    )
  )
  .required('Shifts are required');

export const validationSchema = object({
  [SUB_ORGANIZATION]: object({
    [ID]: string().required('Pflegeheim ist erforderlich'),
  }).typeError('Pflegeheim ist erforderlich'),
  [DAYS]: number().min(1, 'Mindestens 1').max(10, 'Maximal 10').required('Bitte wählen Sie die Anzahl der Tage'),
  [SHIFTS]: shiftsValidation,
  [REGISTRATION_DATE]: date()
    .max(ref(`${SHIFTS}.0.0`), 'Der Anmeldeschluss darf nicht nach dem ersten Messtag liegen.')
    .required('Bitte wählen Sie das Registrierungsdatum'),
});
