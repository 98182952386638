import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { Box } from './styles';

const Curtain = ({ index, onChange, before }) => {
  const onHoverStart = useCallback(() => onChange({ index, before }), [before, index, onChange]);
  const onHoverEnd = useCallback(() => onChange(null), [onChange]);

  return <Box onMouseEnter={onHoverStart} onMouseLeave={onHoverEnd} $top={before} />;
};

Curtain.defaultProps = { before: false };
Curtain.propTypes = {
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  before: PropTypes.bool,
};

export default Curtain;
