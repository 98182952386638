import React, { useMemo, useCallback } from 'react';
import { useField, useFormikContext } from 'formik';
import { tail, pathOr, path } from 'ramda';
import PropTypes from 'prop-types';

import {
  WITHOUT_CORRECTION,
  WITH_GLASSES,
  BEST_CORRECTION,
  VISUAL_ACUITY,
  CORRECTION_ITEMS,
  RIGHT,
  LEFT,
} from 'store/examinations';
import { getBestCorrectionItems, needResetBestCorrection } from 'store/examinations/utils';
import Section from '../Section';

import { Select } from './styles';

const Dropdowns = ({ side, readMode }) => {
  const { setValues } = useFormikContext();
  const [{ value: withoutCorrection }, { error: withoutCorrectionErr }] = useField(
    `${side}.${VISUAL_ACUITY}.${WITHOUT_CORRECTION}`
  );
  const hasWithoutCorrectionVal = Boolean(withoutCorrection);
  const [{ value: withGlasses }, { error: withGlassesErr }] = useField(`${side}.${VISUAL_ACUITY}.${WITH_GLASSES}`);
  const hasWithGlassesVal = Boolean(withGlasses);
  const [{ value: bestCorrection }, { error: bestCorrectionErr }, { setValue: setBestCorrection }] = useField(
    `${side}.${VISUAL_ACUITY}.${BEST_CORRECTION}`
  );
  const withoutCorrectionOptions = useMemo(
    () => (hasWithoutCorrectionVal ? CORRECTION_ITEMS : tail(CORRECTION_ITEMS)),
    [hasWithoutCorrectionVal]
  );
  const withGlassesOptions = useMemo(() => (hasWithGlassesVal ? CORRECTION_ITEMS : tail(CORRECTION_ITEMS)), [hasWithGlassesVal]);
  const bestCorrectionOptions = useMemo(
    () => getBestCorrectionItems(withoutCorrection || '', withGlasses || ''),
    [withGlasses, withoutCorrection]
  );
  const setWithoutCorrection = useCallback(
    (value) => {
      setValues(($) => ({
        ...$,
        [side]: {
          ...$[side],
          [VISUAL_ACUITY]: {
            ...pathOr({}, [side, VISUAL_ACUITY], $),
            [WITHOUT_CORRECTION]: value,
            ...(needResetBestCorrection(
              value || '',
              path([side, VISUAL_ACUITY, WITH_GLASSES], $) || '',
              path([side, VISUAL_ACUITY, BEST_CORRECTION], $) || ''
            ) && {
              [BEST_CORRECTION]: '',
            }),
          },
        },
      }));
    },
    [setValues, side]
  );
  const setWithGlasses = useCallback(
    (value) =>
      setValues(($) => ({
        ...$,
        [side]: {
          ...$[side],
          [VISUAL_ACUITY]: {
            ...pathOr({}, [side, VISUAL_ACUITY], $),
            [WITH_GLASSES]: value,
            ...(needResetBestCorrection(
              path([side, VISUAL_ACUITY, WITHOUT_CORRECTION], $) || '',
              value || '',
              path([side, VISUAL_ACUITY, BEST_CORRECTION], $) || ''
            ) && {
              [BEST_CORRECTION]: '',
            }),
          },
        },
      })),
    [setValues, side]
  );

  return (
    <>
      <Section label="Ohne Korrektur">
        <Select
          value={withoutCorrection || ''}
          options={withoutCorrectionOptions}
          onSelect={setWithoutCorrection}
          error={withoutCorrectionErr}
          readMode={readMode}
          $width="190"
        />
      </Section>
      <Section label="Mit bestehender Brille">
        <Select
          value={withGlasses || ''}
          options={withGlassesOptions}
          onSelect={setWithGlasses}
          error={withGlassesErr}
          readMode={readMode}
          $width="190"
        />
      </Section>
      <Section label="Bestmöglich korrigiert">
        <Select
          value={bestCorrection || ''}
          options={bestCorrectionOptions}
          onSelect={setBestCorrection}
          error={bestCorrectionErr}
          readMode={readMode}
          disabled={!(hasWithoutCorrectionVal || hasWithGlassesVal)}
          $width="205"
        />
      </Section>
    </>
  );
};

Dropdowns.propTypes = {
  side: PropTypes.oneOf([RIGHT, LEFT]).isRequired,
  readMode: PropTypes.bool.isRequired,
};

export default Dropdowns;
