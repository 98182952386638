import { PUBLIC_ID, DATE, FIRST_NAME, LAST_NAME, BIRTH, STATUS } from 'store/patients';
import { WARD, SORT_BY, SORT_DIR, ASC, REGISTRATIONS } from 'store/planning';

export const DEFAULT_SORT = { [SORT_BY]: `${REGISTRATIONS}.${DATE}`, [SORT_DIR]: ASC };

export const TABLE_HEAD = [
  { id: `${REGISTRATIONS}.${PUBLIC_ID}`, content: 'ID', width: '8%' },
  { id: `${REGISTRATIONS}.${DATE}`, content: 'Datum', width: '19%' },
  { id: `${REGISTRATIONS}.${FIRST_NAME}`, content: 'Vorname', width: '14%' },
  { id: `${REGISTRATIONS}.${LAST_NAME}`, content: 'Nachname', width: '18%' },
  { id: `${REGISTRATIONS}.${BIRTH}`, content: 'Geburtstag', width: '13%' },
  { id: `${REGISTRATIONS}.${WARD}`, content: 'Station', width: '13%' },
  { id: `${REGISTRATIONS}.${STATUS}`, content: 'Status', width: '15%' },
];
