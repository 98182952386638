import React, { useCallback, useMemo } from 'react';
import { useField } from 'formik';
import PropTypes from 'prop-types';

import { ID, ORGANIZATION_KINDS, NO_GP_REPORTS, useCollectOrganizationsAction } from 'store/organizations';
import { FAMILY_DOCTOR, EYE_DOCTOR, SUB_ORGANIZATION, CORRESPONDENCE, NURSING_HOME } from 'store/patients';
import Checkbox from 'components/CheckboxNew';
import PaginationDropDown from 'components/PaginationDropDown';
import SelectedOrganization from 'components/SelectedOrganization';
import CreateComponent from '../CreateOrganizationItem';
import { WarnMessage } from '../styles';

import { featureMap } from './utils';

const Clinic = ({ readMode, feature }) => {
  const [nameKey, params, handleClinicName, placeholder, specialty] = useMemo(() => featureMap[feature], [feature]);
  const getOrganizations = useCollectOrganizationsAction(ORGANIZATION_KINDS.CLINIC, params);

  const [{ value: clinic }, , { setValue: setClinic }] = useField([feature, SUB_ORGANIZATION].join('.'));
  const [{ value: reporter }, , { setValue: setReporter }] = useField([feature, CORRESPONDENCE].join('.'));
  const [{ value: noGPReports }] = useField(`${NURSING_HOME}.${SUB_ORGANIZATION}.${NO_GP_REPORTS}`);

  const handleChangeReporter = useCallback(() => {
    if (!readMode && clinic) {
      setReporter(!reporter);
    }
  }, [clinic, readMode, reporter, setReporter]);

  const onChange = useCallback(
    (value) => {
      setClinic(value);

      if (!value) setReporter(false, false);
    },
    [setClinic, setReporter]
  );

  const clinicThroughFiler = useMemo(() => {
    if (!clinic) return null;
    if (clinic[nameKey]) return clinic;

    return handleClinicName([clinic])[0];
  }, [handleClinicName, clinic, nameKey]);

  const latestItem = useMemo(() => <CreateComponent kind={ORGANIZATION_KINDS.CLINIC} specialty={specialty} />, [specialty]);

  return (
    <>
      <Checkbox label="Erhält Korrespondenz" selected={reporter} onClick={handleChangeReporter} readMode={readMode || !clinic} />
      <PaginationDropDown
        idKey={ID}
        nameKey={nameKey}
        action={getOrganizations}
        placeholder={placeholder}
        value={clinicThroughFiler}
        onChange={onChange}
        applyFilter={handleClinicName}
        latestItem={latestItem}
        selectedComponent={SelectedOrganization}
        disabled={readMode}
        withoutRemoving
        onlyOne
      />
      {feature === FAMILY_DOCTOR && Boolean(noGPReports) && (
        <WarnMessage type="error">
          Hinweis: Das Pflegezentrum wünscht dass keine Berichte an Hausärzte gesendet werden.
        </WarnMessage>
      )}
    </>
  );
};

Clinic.propTypes = {
  readMode: PropTypes.bool.isRequired,
  feature: PropTypes.oneOf([FAMILY_DOCTOR, EYE_DOCTOR]).isRequired,
};

export default Clinic;
