import { useMemo, useEffect } from 'react';
import moment from 'moment';
import { uniq, last, times } from 'ramda';

import {
  usePlanningActions,
  useAlerts,
  ID,
  DATE,
  SHIFTS,
  SUB_ORGANIZATION,
  LIMIT,
  CURSOR,
  FIELDS,
  EXPAND,
  SORT_BY,
  SORT_DIR,
  DESC,
  FROM,
  TO,
  STATUS,
} from 'store/planning';
import { STATUSES } from '../../consts';

import { ONE_DAY, getDateKey } from './utils';

const getShiftRanges = (range) =>
  range.reduce(
    (acc, date) => {
      const week = moment(date).week();

      if (acc.items.length === 0 || acc.week !== week) {
        acc.items.push([date]);
        acc.week = week;

        return acc;
      }

      const lastDate = last(last(acc.items));
      const diff = Math.abs(moment(date).valueOf() - moment(lastDate).valueOf());

      if (diff > ONE_DAY) {
        acc.items.push([date]);
      } else {
        last(acc.items).push(date);
      }

      return acc;
    },
    { items: [], week: 0 }
  ).items;

const combineStrings = (str1, str2) => {
  let result = '';

  for (let i = 0; i < str1.length; i += 1) {
    if (str2[i] === '1' && str1[i] === str2[i]) {
      result = null;
    } else if (result === null) {
      i = str1.length;
    } else {
      result += str1[i] === '1' || str2[i] === '1' ? '1' : '0';
    }
  }

  return result;
};
const rowCreator = () => {
  let [list, index, isFound] = [['0'.repeat(7)], null, false];

  return (start, length) => {
    index = null;
    isFound = false;
    const str = times((i) => Number(i >= start && i < start + length), Math.min(7, Math.max(7, start + length))).join('');

    list = list.map((tmp, i) => {
      if (isFound) return tmp;

      const next = combineStrings(tmp, str);

      if (!next) return tmp;

      isFound = true;
      index = i;

      return next;
    });

    if (index === null) {
      list.push(combineStrings('0'.repeat(7), str));

      return list.length - 1;
    }

    return index;
  };
};

const handleScheduleList = (days, schedule) => {
  const scheduleData = schedule
    .map(({ [ID]: id, [SHIFTS]: shifts, [SUB_ORGANIZATION]: organization, [STATUS]: status }) => ({
      id,
      range: uniq(shifts.map(([d]) => getDateKey(d))),
      name: organization.name,
      color: STATUSES[status]?.color,
    }))
    .reduce((acc, { id, range, name, color }) => {
      getShiftRanges(range).forEach((items) => {
        acc.push({ id, range: items, name, color });
      });

      return acc;
    }, []);

  const getRow = rowCreator();

  return days.map(({ key, index }) =>
    scheduleData.reduce((acc, { id, range, name, color }) => {
      if (range[0] === key) acc.push({ id, column: index, row: getRow(index, range.length), name, days: range.length, color });

      return acc;
    }, [])
  );
};

export const useScheduleLines = (days) => {
  const { fetchCalenderSchedules } = usePlanningActions();
  const { action, loading, success, error } = useAlerts(fetchCalenderSchedules);
  const list = useMemo(() => (success ? handleScheduleList(days, success) : []), [days, success]);
  const [from, to] = useMemo(
    () => [days[0].date.toISOString(), new Date(last(days).date.valueOf() + ONE_DAY - 1).toISOString()],
    [days]
  );

  useEffect(() => {
    action({
      [LIMIT]: 100,
      [CURSOR]: 0,
      [FIELDS]: [DATE, SUB_ORGANIZATION, SHIFTS, FROM, TO, STATUS],
      [EXPAND]: [SUB_ORGANIZATION],
      [SORT_BY]: DATE,
      [SORT_DIR]: DESC,
      [FROM]: from,
      [TO]: to,
    });
  }, [action, from, to]);

  return { list, loading, error };
};
