export const FEATURE_NAME = 'planning';

export const CURRENT = 'current';
export const UPDATES = 'updates';
export const FILTERS = 'filters';

export const DAYS = 'days';
export const SHIFTS = 'shifts';
export const REGISTRATIONS = 'registrations';
export const NAME = 'name';
export const WARD = 'ward';
export const CONFIDENCE = 'confidence';
export const INDEX = 'index';
export const FILES = 'files';
export const REPRESENTATIVE = 'representative';
export const DIABETES = 'diabetes';
export const SUB_EYE_DOCTOR = '_eyeDoctor';
export const SUB_GP = '_gp';
export const SUB_FORM = '_form';
export const PATIENT = 'patient';
export const RESCHEDULE = 'reschedule';
export const REGISTRATION_DATE = 'registrationDate';
export const SUB_TEAM_MEMBERS = '_teamMembers';
export const FROM = 'from';
export const TO = 'to';
