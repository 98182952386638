import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react';
import { equals, prop } from 'ramda';
import PropTypes from 'prop-types';

import { SORT_BY, SORT_DIR, useAlerts, usePlanningActions } from 'store/planning';
import Table from 'components/Table';
import { TABLE_HEAD, DEFAULT_SORT } from '../utils';

import { useFilter } from './hooks';
import Row from './Row';

const MainTable = ({ list, sort, setSort, shifts, loading }) => {
  const positionRef = useRef(null);
  const [draggable, setDraggable] = useState(null);
  const updatePosition = useCallback((pose) => {
    positionRef.current = pose;
  }, []);
  const { reorderSchedule } = usePlanningActions();
  const { action, loading: reloadLoading, success, error, resetAlerts } = useAlerts(reorderSchedule);
  const getDragColor = useCallback(
    ({ id }) => {
      if (id !== draggable?.id) return '';
      if (success) return 'rgba(131, 223, 180, 0.5)';
      if (error) return 'rgba(247, 146, 83, 0.5)';

      return '';
    },
    [draggable?.id, error, success]
  );
  const [filter, items, selectComponent] = useFilter(shifts, list, loading || reloadLoading);
  const dragIsEnable = useMemo(() => (!filter || filter === 'all') && equals(sort, DEFAULT_SORT), [filter, sort]);
  const onReorder = useCallback(() => {
    if (!(dragIsEnable && positionRef.current && draggable)) return;

    const { index, before } = positionRef.current;
    const indexes = items
      .map(prop('index'))
      .filter((i) => i !== draggable.index)
      .map((i) => (i === index && (before ? [draggable.index, index] : [index, draggable.index])) || i)
      .flat(1);

    action(indexes);
  }, [dragIsEnable, draggable, items, action]);
  const renderRow = useCallback(
    ($) => (
      <Row
        {...$}
        dragIsEnable={dragIsEnable}
        draggable={draggable}
        setDraggable={setDraggable}
        dragColor={getDragColor($)}
        resetAlerts={resetAlerts}
        onReorder={onReorder}
        updatePosition={updatePosition}
        readMode={Boolean(loading)}
      />
    ),
    [dragIsEnable, draggable, getDragColor, resetAlerts, onReorder, updatePosition, loading]
  );

  useEffect(() => {
    if (draggable && items) updatePosition(null);
  }, [draggable, items, updatePosition]);

  return (
    <>
      {selectComponent}
      <Table
        head={TABLE_HEAD}
        list={items}
        renderRow={renderRow}
        sort={sort}
        updateSort={setSort}
        loading={loading || reloadLoading}
      />
    </>
  );
};

MainTable.defaultProps = { loading: false };
MainTable.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  sort: PropTypes.shape({
    [SORT_BY]: PropTypes.string.isRequired,
    [SORT_DIR]: PropTypes.string.isRequired,
  }).isRequired,
  shifts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired).isRequired,
  setSort: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

export default MainTable;
