import styled from 'styled-components';

export const Box = styled.div`
  position: absolute;
  inset: ${({ $top }) => ($top ? '0 0 auto 0' : 'auto 0 0 0')};
  height: 50%;
  opacity: 0;
  @media (hover) {
    &:hover {
      opacity: 1;
    }
  }
  &:before {
    content: '';
    display: block;
    position: absolute;
    inset: ${({ $top }) => ($top ? '-1px 0 auto 0' : 'auto 0 -1px 0')};
    height: 2px;
    background-color: var(--color-grey97);
  }
`;
