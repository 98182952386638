import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export const Container = styled(motion.div).attrs(({ $color }) => ({
  animate: { backgroundColor: $color || 'rgba(255, 255, 255, 0)' },
  transition: { duration: 0.75 },
  initial: false,
  whileDrag: {
    pointerEvents: 'none',
    backgroundColor: 'rgba(248, 248, 248, 1)',
    opacity: 0.75,
    cursor: 'move',
  },
}))`
  display: flex;
  column-gap: 10px;
  width: 100%;
  height: 55px;
  position: relative;
  border-radius: var(--border-radius8);
`;

export const NavLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius8);
  text-decoration: none;
  cursor: ${({ $readMode }) => ($readMode ? 'auto' : 'pointer')};
  @media (hover) {
    &:hover {
      background-color: ${({ $readMode }) => ($readMode ? 'none' : 'var(--color-greyF8)')};
    }
  }
`;

export const Text = styled.p`
  display: block;
  width: ${({ $width }) => ($width ? `calc(${$width} - 10px)` : 'auto')};
  font-size: ${({ $fontSize }) => ($fontSize === 10 ? 'var(--font-size10)' : 'var(--font-size18)')};
  font-weight: ${({ $fontSize }) => ($fontSize === 10 ? 600 : 400)};
  letter-spacing: ${({ $fontSize }) => ($fontSize === 10 ? 1.01 : 0.5)}px;
  color: ${({ $color }) => $color || 'var(--color-grey6A)'};
  text-transform: ${({ $fontSize }) => ($fontSize === 10 ? 'uppercase' : 'none')};
`;
