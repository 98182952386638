import { props, applySpec, prop, pipe, filter, join, map, propOr, ifElse, allPass, length, propSatisfies } from 'ramda';
import moment from 'moment';

import { ID, COMMENT, SUB_PATIENT } from 'store/examinations';
import { parseBirthday } from 'utils';

const preparePatient = applySpec({
  patientId: prop(ID),
  publicId: prop('publicId'),
  birth: pipe(
    prop('birthday'),
    ifElse(
      allPass([($) => moment($).isValid(), Boolean]),
      ($) => parseBirthday($),
      () => ''
    )
  ),
  name: pipe(props(['firstname', 'lastname']), filter(Boolean), join(', ')),
});

const prepareProgress = map(
  applySpec({
    id: prop(ID),
    name: prop('task'),
    done: prop('done'),
    time: pipe(
      prop('timestamp'),
      ifElse(
        allPass([($) => moment($).isValid(), Boolean]),
        ($) => $,
        () => ''
      )
    ),
  })
);

const estimateProgress = (progress) => {
  const tasks = length(progress);
  const done = pipe(filter(propSatisfies(Boolean, 'done')), length)(progress);

  return tasks && Math.round((done / tasks) * 100);
};

export const extractData = map(
  applySpec({
    id: prop(ID),
    patient: pipe(propOr({}, SUB_PATIENT), preparePatient),
    progress: pipe(propOr([], 'progress'), prepareProgress),
    grade: pipe(propOr([], 'progress'), estimateProgress),
    comment: prop(COMMENT),
  })
);
