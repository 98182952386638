import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { sum } from 'ramda';
import PropTypes from 'prop-types';

import { usePlanningActions, useAlerts, ID, FILE } from 'store/planning';
import planningTemplate from 'assets/planningTemplate.xlsx';
import Button from 'components/Button';
import Spinner from 'components/FullScreenSpinner';
import FormHeader from '../../../FormHeader';
import Section from '../../../Section';
import UploadXLS from '../../UploadXLS';

import UploadMultiPDF from './UploadMultiPDF';
import { Wrapper, SelectBtn, Link } from './styles';

const Step3 = ({ setStep, xlsFile, setXlsFile, formMedia, setFormMedia, refetchFormMedia, formMediaLoading }) => {
  const { id } = useParams();
  const [type, setType] = useState('pdf');
  const updateType = useCallback(({ currentTarget }) => setType(currentTarget.value), []);
  const { createScheduleXML, createScheduleForms } = usePlanningActions();
  const { extractId, loading, error, errors } = useAlerts();
  const onBack = useCallback(() => setStep(2), [setStep]);
  const onNext = useCallback(() => {
    if (type === 'xls' && xlsFile) extractId(createScheduleXML({ [ID]: id, [FILE]: xlsFile }));
    if (type === 'pdf' && formMedia?.length) extractId(createScheduleForms(id));
  }, [createScheduleForms, createScheduleXML, extractId, xlsFile, formMedia?.length, id, type]);
  const isPDFDisabled = useMemo(
    () => type === 'pdf' && sum(formMedia.map(({ items }) => items.length || 0)) === 0,
    [formMedia, type]
  );

  return (
    <>
      <FormHeader onBack={onBack} />
      <Section title="Wie möchten Sie die Patienten importieren?" current={3} amount={3} withCounter />
      <Wrapper>
        <SelectBtn type="button" value="xls" onClick={updateType} disabled={type === 'xls' || formMediaLoading}>
          Via Excel-Template
        </SelectBtn>
        <SelectBtn type="button" value="pdf" onClick={updateType} disabled={type === 'pdf' || formMediaLoading}>
          Via Einverständniserklärung (PDF-Scans)
        </SelectBtn>
      </Wrapper>
      {type === 'pdf' && (
        <UploadMultiPDF
          files={formMedia}
          updateFiles={setFormMedia}
          refetchMedias={refetchFormMedia}
          refetchLoading={formMediaLoading}
        />
      )}
      {type === 'xls' && (
        <UploadXLS label="Patientenliste" value={xlsFile} onChange={setXlsFile} loading={loading} error={error} errors={errors} />
      )}
      {!type && formMediaLoading && <Spinner height="200px" />}
      <Wrapper>
        <Button type="button" onClick={onBack} height="40px">
          Zurück
        </Button>
        <Button
          type="button"
          onClick={onNext}
          height="40px"
          color="normal"
          disabled={!type || (type === 'xls' && !xlsFile) || isPDFDisabled || formMediaLoading}
        >
          Importieren
        </Button>
      </Wrapper>
      {type === 'xls' && (
        <Link href={planningTemplate} download="Template herunterladen" target="_blank" rel="noopener noreferrer">
          Template herunterladen
        </Link>
      )}
    </>
  );
};

Step3.defaultProps = {
  xlsFile: null,
  formMedia: null,
  formMediaLoading: false,
};
Step3.propTypes = {
  setStep: PropTypes.func.isRequired,
  xlsFile: PropTypes.instanceOf(File),
  setXlsFile: PropTypes.func.isRequired,
  formMedia: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      ward: PropTypes.string.isRequired,
      items: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
      wait: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    }).isRequired
  ),
  setFormMedia: PropTypes.func.isRequired,
  refetchFormMedia: PropTypes.func.isRequired,
  formMediaLoading: PropTypes.bool,
};

export default Step3;
