import styled from 'styled-components';

import Msg from 'components/Message';
import SelectDef from 'components/SelectNew';

export const Message = styled(Msg)`
  text-align: center;
`;

export const Select = styled(SelectDef)`
  margin: 20px 0 30px;
`;
