import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react';
import { isEmpty, cond, equals } from 'ramda';
import PropTypes from 'prop-types';

import { parseDate } from 'utils';
import TextInput from 'components/TextInputNew';

import { validateDate } from './utils';
import { Container, Wrapper, Message } from './styles';

const DateInput = ({ value, onChange, setValidationError, allowAllDays, pastOnly, utcDate, readMode, disabled }) => {
  const [dayRef, monthRef, yearRef] = [useRef(null), useRef(null), useRef(null)];
  const [state, setState] = useState(() => {
    const date = new Date(value);
    const day = date.getDate();
    const month = date.getMonth() + 1;

    return { day: day > 9 ? day : `0${day}`, month: month > 9 ? month : `0${month}`, year: date.getFullYear(), errors: {} };
  });
  const textValue = useMemo(() => (readMode && parseDate(value)) || '', [readMode, value]);

  const onFocus = useCallback(({ currentTarget }) => currentTarget.select(), []);
  const onWheel = useCallback((e) => {
    const isNeedRefocus = window.document.activeElement === e.target;

    e.target.blur();
    e.stopPropagation();

    if (isNeedRefocus) setTimeout(() => e?.target?.focus?.(), 0);
  }, []);

  const handleChange = useCallback(
    ({ currentTarget }) => {
      const { name, value: val } = currentTarget;
      const currentDay = Number(name === 'day' ? val : dayRef.current.value);
      const currentMonth = Number(name === 'month' ? val : monthRef.current.value);
      const currentYear = Number(name === 'year' ? val : yearRef.current.value);
      const errs = validateDate({ day: currentDay, month: currentMonth, year: currentYear, allowAllDays, pastOnly });

      setState(($) => ({ ...$, [name]: val ? Number(val) : val, errors: errs }));

      if (isEmpty(errs))
        onChange(
          (utcDate
            ? new Date(Date.UTC(currentYear, currentMonth - 1, currentDay))
            : new Date(currentYear, currentMonth - 1, currentDay)
          ).toISOString()
        );

      cond([
        [
          equals('day'),
          (n, v) => {
            if (!v && (monthRef.current.value || yearRef.current.value)) {
              monthRef.current.value || !yearRef.current.value ? monthRef.current?.focus?.() : yearRef.current.focus?.();
            }
            if (v && (v.length === 2 || currentDay > 3)) monthRef.current?.focus?.();
          },
        ],
        [
          equals('month'),
          (n, v) => {
            if (!v && (yearRef.current.value || dayRef.current.value)) {
              yearRef.current.value || !dayRef.current.value ? yearRef.current.focus?.() : dayRef.current?.focus?.();
            }
            if (v && (v.length === 2 || currentMonth > 1)) {
              !yearRef.current.value || dayRef.current.value ? yearRef.current?.focus?.() : dayRef.current?.focus?.();
            }
          },
        ],
        [
          equals('year'),
          (n, v) => {
            if (!v && (monthRef.current.value || dayRef.current.value)) {
              monthRef.current.value || !dayRef.current.value ? monthRef.current?.focus?.() : dayRef.current?.focus?.();
            }
            if (v && v.length === 4 && !dayRef.current.value) dayRef.current?.focus?.();
            if (v && v.length === 4 && !monthRef.current.value) monthRef.current?.focus?.();
          },
        ],
      ])(name, val);
    },
    [dayRef, monthRef, yearRef, allowAllDays, pastOnly, onChange, utcDate]
  );

  useEffect(() => {
    setValidationError(($) => ($ === state.errors.message ? $ : state.errors.message));
  }, [setValidationError, state.errors.message]);

  return readMode ? (
    <Wrapper>
      <TextInput readMode={readMode} value={textValue} mb={0} />
    </Wrapper>
  ) : (
    <Container>
      <TextInput
        ref={dayRef}
        name="day"
        type="number"
        placeholder="DD"
        value={state.day}
        onChange={handleChange}
        error={state.errors.day}
        disabled={disabled}
        onFocus={onFocus}
        onWheel={onWheel}
        mb={0}
        min={1}
        max={31}
        step={1}
      />
      <TextInput
        ref={monthRef}
        name="month"
        type="number"
        placeholder="MM"
        value={state.month}
        onChange={handleChange}
        error={state.errors.month}
        disabled={disabled}
        onFocus={onFocus}
        onWheel={onWheel}
        mb={0}
        min={1}
        max={12}
        step={1}
      />
      <TextInput
        ref={yearRef}
        name="year"
        type="number"
        placeholder="YYYY"
        value={state.year}
        onChange={handleChange}
        error={state.errors.year}
        disabled={disabled}
        onFocus={onFocus}
        onWheel={onWheel}
        mb={0}
        min={1870}
        max={Infinity}
        step={1}
      />
      {state.errors.message && <Message>{state.errors.message}</Message>}
    </Container>
  );
};

DateInput.defaultProps = {
  value: null,
  onChange: () => null,
  setValidationError: () => null,
  allowAllDays: false,
  pastOnly: false,
  utcDate: false,
  readMode: false,
  disabled: false,
};
DateInput.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  setValidationError: PropTypes.func,
  allowAllDays: PropTypes.bool,
  pastOnly: PropTypes.bool,
  utcDate: PropTypes.bool,
  readMode: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default DateInput;
