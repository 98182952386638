/* eslint-disable no-param-reassign */
import { path, mergeRight, pick } from 'ramda';

import {
  RIGHT,
  LEFT,
  VISUAL_ACUITY,
  WITHOUT_CORRECTION,
  WITH_GLASSES,
  BEST_CORRECTION,
  PUPIL_SIZE,
  SPHERE,
  CYLINDER,
  AXIS,
  CONFIDENCE,
  CORRECTION_ITEMS,
  BEST_CORRECTION_ITEMS,
} from './consts';
import { FILE } from '.';

const filterField = pick([
  WITHOUT_CORRECTION,
  WITH_GLASSES,
  BEST_CORRECTION,
  PUPIL_SIZE,
  SPHERE,
  CYLINDER,
  AXIS,
  CONFIDENCE,
  FILE,
]);

export const getExaminationFromXml = (currentExam, xmlData) =>
  [RIGHT, LEFT].reduce(
    (init, key) => {
      init[key][VISUAL_ACUITY] = mergeRight(path([key, VISUAL_ACUITY], init), filterField(xmlData[key]));

      return init;
    },
    { ...currentExam }
  );

const correctionItemsObject = CORRECTION_ITEMS.reduce((acc, item) => {
  if (item.id) acc[item.id] = item;

  return acc;
}, {});
export const getBestCorrectionItems = (withoutCorrection, withGlasses) => {
  const withoutCorrectionValue = correctionItemsObject[withoutCorrection]?.value || 0;
  const withGlassesValue = correctionItemsObject[withGlasses]?.value || 0;
  const compareValue =
    (withoutCorrectionValue === 0 && withGlassesValue) ||
    (withGlassesValue === 0 && withoutCorrectionValue) ||
    (withoutCorrectionValue < withGlassesValue ? withGlassesValue : withoutCorrectionValue);

  return BEST_CORRECTION_ITEMS.map(({ id, label, value }) => ({
    id,
    label,
    disabled: (() => {
      if (id === '') return false;
      if (id === 'andere Gläser bessern nicht') return !withGlasses;
      if (!(withoutCorrection || withGlasses)) return true;
      if (id === 'Gläser bessern nicht') return Boolean(withGlasses);

      return compareValue > value;
    })(),
  }));
};
export const needResetBestCorrection = (withoutCorrection, withGlasses, bestCorrect) =>
  getBestCorrectionItems(withoutCorrection, withGlasses).reduce((acc, { id, disabled }) => {
    if (id) acc[id] = disabled;

    return acc;
  }, {})[bestCorrect];
