import { takeLeading, throttle, call, put, select, debounce, all, takeEvery } from 'redux-saga/effects';
import { path, pick, omit, keys, isEmpty, prop } from 'ramda';

import api from 'api';
import { withAlert } from 'store/alerts';
import { NURSE } from 'permissions';

import {
  CREATE_SCHEDULE,
  FETCH_SCHEDULES,
  FETCH_SCHEDULE,
  FETCH_SCHEDULE_UNSUBSCRIBERS,
  CREATE_SCHEDULE_XML,
  SAVE_SCHEDULE_XML,
  SAVE_SCHEDULE,
  CREATE_REGISTRATION,
  SAVE_REGISTRATION,
  EXPORT_SCHEDULE,
  GET_SCHEDULE_LABELS,
  GET_SCHEDULE_COVER_LETTERS,
  GET_SCHEDULE_CONSENTS,
  FETCH_SCHEDULE_FORMS,
  CREATE_SCHEDULE_FORMS,
  UPLOAD_SCHEDULE_FORM_MEDIA,
  REMOVE_PATIENT_FROM_SCHEDULE,
  SAVE_SCHEDULE_FORM_MEDIAS,
  SAVE_SCHEDULE_FORM_MEDIA,
  REMOVE_SCHEDULE_FORM_MEDIA,
  FETCH_COLLECT_NURSES,
  FETCH_CALENDAR_SCHEDULES,
  REORDER_SCHEDULE,
} from './types';
import { updatePagination, setCurrent, resetUpdates } from './actions';
import { getCurrentSchedule, getScheduleUpdates } from './selectors';
import {
  SUB_ORGANIZATION,
  ID,
  DATE,
  REGISTRATION_DATE,
  DAYS,
  SHIFTS,
  FILE,
  EXPAND,
  META,
  EDITOR,
  REGISTRATIONS,
  SUB_PATIENT,
  STATUS,
  LIMIT,
  SUB_EYE_DOCTOR,
  SUB_GP,
  WARD,
  SUB_EXAMINATION,
  RESCHEDULE,
  SUB_FORM,
  SUB_TEAM_MEMBERS,
} from '.';

const scheduleExpandParams = {
  [EXPAND]: [
    SUB_ORGANIZATION,
    `${META}.${EDITOR}`,
    `${REGISTRATIONS}.${SUB_PATIENT}`,
    `${REGISTRATIONS}.${SUB_ORGANIZATION}`,
    `${REGISTRATIONS}.${SUB_EYE_DOCTOR}`,
    `${REGISTRATIONS}.${SUB_GP}`,
    `${REGISTRATIONS}.${SUB_EXAMINATION}`,
    `${REGISTRATIONS}.${SUB_FORM}`,
    SUB_TEAM_MEMBERS,
  ],
};

function* createSchedule({ payload }) {
  const body = {
    [SUB_ORGANIZATION]: path([SUB_ORGANIZATION, ID], payload),
    ...pick([DATE, DAYS, SHIFTS, REGISTRATION_DATE], payload),
    [SUB_TEAM_MEMBERS]: (payload[SUB_TEAM_MEMBERS] || []).map(prop(ID)).filter(Boolean),
  };

  const { [ID]: id } = yield call(api.createSchedule, body);

  return { success: id };
}

function* fetchSchedules({ payload }) {
  const { data, ...pagination } = yield call(api.getSchedules, payload);

  yield put(updatePagination(pagination));

  return { success: data };
}

function* fetchCalenderSchedules({ payload }) {
  const { data } = yield call(api.getSchedules, payload);

  return { success: data };
}

function* fetchSchedule({ payload }) {
  const data = yield call(api.getSchedule, payload[ID], omit([ID], payload));

  yield put(setCurrent(data));
}

function* fetchScheduleUnsubscribers({ payload }) {
  return { success: yield call(api.getScheduleUnsubscribers, payload) };
}

function* createScheduleXML({ payload }) {
  const data = yield call(api.createScheduleXML, payload[ID], payload[FILE], scheduleExpandParams);

  yield put(setCurrent(data));
}

function* saveScheduleXML({ payload }) {
  const data = yield call(api.upgradeScheduleXML, payload[ID], payload[FILE], scheduleExpandParams);

  yield put(setCurrent(data));
  yield put(resetUpdates(data[ID]));
}

function* saveSchedule({ payload }) {
  const schedule = yield select(getCurrentSchedule);
  const updates = payload || (yield select(getScheduleUpdates(schedule[ID]))) || {};

  const body = {
    ...(payload ? { [SUB_ORGANIZATION]: path([SUB_ORGANIZATION, ID], payload) } : {}),
    [DATE]: updates[DATE] || schedule[DATE],
    [DAYS]: updates[DAYS] || schedule[SHIFTS].length,
    [SHIFTS]: updates[SHIFTS] || schedule[SHIFTS],
    [REGISTRATION_DATE]: updates[REGISTRATION_DATE] || schedule[REGISTRATION_DATE],
    [SUB_TEAM_MEMBERS]: (updates[SUB_TEAM_MEMBERS] || schedule[SUB_TEAM_MEMBERS] || []).map(prop(ID)).filter(Boolean),
    ...(!payload && updates[RESCHEDULE] && { [RESCHEDULE]: true }),
    ...(updates[REGISTRATIONS] &&
      !isEmpty(updates[REGISTRATIONS]) && {
        [REGISTRATIONS]: keys(updates[REGISTRATIONS]).map((id) => ({
          [ID]: id,
          [STATUS]: path([REGISTRATIONS, id], updates),
        })),
      }),
  };

  const data = yield call(api.saveSchedule, schedule[ID], body, scheduleExpandParams);

  yield put(setCurrent(data));
  yield put(resetUpdates(schedule[ID]));
}

function* saveRegistration({ payload }) {
  const schedule = yield select(getCurrentSchedule);
  const data = yield call(api.saveRegistration, schedule[ID], payload[ID], omit([ID, 'sort'], payload), {
    ...scheduleExpandParams,
    ...payload.sort,
  });

  yield put(setCurrent(data));
}

function* createRegistration({ payload }) {
  const schedule = yield select(getCurrentSchedule);
  const data = yield call(api.createScheduleRegistration, schedule[ID], payload, {
    [EXPAND]: [SUB_PATIENT, SUB_ORGANIZATION, SUB_EYE_DOCTOR, SUB_GP, SUB_EXAMINATION, SUB_FORM],
  });

  yield put(setCurrent({ ...schedule, [REGISTRATIONS]: [data, ...schedule[REGISTRATIONS]] }));

  return { success: data[ID] };
}

function* removePatientFromSchedule({ payload }) {
  const schedule = yield select(getCurrentSchedule);
  const data = yield call(api.removePatientSchedule, schedule[ID], payload[ID], {
    ...scheduleExpandParams,
    ...payload.sort,
  });

  yield put(setCurrent(data));
}

function* exportSchedule({ payload }) {
  return { success: yield call(api.exportSchedule, payload) };
}

function* getScheduleLabels({ payload }) {
  return { success: yield call(api.getScheduleLabels, payload) };
}

function* getScheduleCoverLetters({ payload }) {
  return { success: yield call(api.getScheduleCoverLetters, payload) };
}

function* getScheduleConsents({ payload }) {
  return { success: yield call(api.getScheduleConsents, payload) };
}

function* fetchScheduleForms({ payload }) {
  const { data } = yield call(api.getScheduleForms, payload, { [LIMIT]: 100 });
  return { success: data };
}

function* createScheduleForms({ payload }) {
  const data = yield call(api.createScheduleForms, payload, scheduleExpandParams);

  yield put(setCurrent(data));
}

function* uploadScheduleFormMedia({ payload }) {
  const { files, id, ward } = payload;

  return {
    success: yield all(files.map((file) => call(api.uploadScheduleForm, id, file, ward))),
  };
}

function* saveScheduleFormMedias({ payload }) {
  const { id: scheduleId, files, ward } = payload;

  yield all(
    files.map(({ [ID]: id, form }) => call(api.saveScheduleFormMedia, scheduleId, id, { form: { ...form, [WARD]: ward } }))
  );
}

function* saveScheduleFormMedia({ payload }) {
  const { scheduleId, id, form } = payload;

  yield call(api.saveScheduleFormMedia, scheduleId, id, { form });
}

function* removeScheduleFormMedia({ payload }) {
  const schedule = yield select(getCurrentSchedule);

  yield call(api.removeScheduleFormMedia, schedule[ID], payload);
}

function* fetchCollectNurses({ payload }) {
  return { success: yield call(api.getUsers, { role: NURSE, ...payload }) };
}

function* reorderSchedule({ payload }) {
  const schedule = yield select(getCurrentSchedule);
  const data = yield call(api.reorderSchedule, schedule[ID], { indexes: payload }, scheduleExpandParams);

  yield put(setCurrent(data));
}

export default function* watchPlanning() {
  yield takeLeading(CREATE_SCHEDULE, withAlert(createSchedule));
  yield throttle(500, FETCH_SCHEDULES, withAlert(fetchSchedules));
  yield throttle(500, FETCH_SCHEDULE, withAlert(fetchSchedule));
  yield takeLeading(FETCH_SCHEDULE_UNSUBSCRIBERS, withAlert(fetchScheduleUnsubscribers));
  yield takeLeading(CREATE_SCHEDULE_XML, withAlert(createScheduleXML));
  yield takeLeading(SAVE_SCHEDULE_XML, withAlert(saveScheduleXML));
  yield takeLeading(SAVE_SCHEDULE, withAlert(saveSchedule));
  yield takeLeading(CREATE_REGISTRATION, withAlert(createRegistration));
  yield debounce(500, SAVE_REGISTRATION, withAlert(saveRegistration));
  yield takeLeading(EXPORT_SCHEDULE, withAlert(exportSchedule));
  yield takeLeading(GET_SCHEDULE_LABELS, withAlert(getScheduleLabels));
  yield takeLeading(GET_SCHEDULE_COVER_LETTERS, withAlert(getScheduleCoverLetters));
  yield takeLeading(GET_SCHEDULE_CONSENTS, withAlert(getScheduleConsents));
  yield takeLeading(FETCH_SCHEDULE_FORMS, withAlert(fetchScheduleForms));
  yield takeLeading(CREATE_SCHEDULE_FORMS, withAlert(createScheduleForms));
  yield takeEvery(UPLOAD_SCHEDULE_FORM_MEDIA, withAlert(uploadScheduleFormMedia));
  yield takeEvery(REMOVE_PATIENT_FROM_SCHEDULE, withAlert(removePatientFromSchedule));
  yield debounce(500, SAVE_SCHEDULE_FORM_MEDIAS, withAlert(saveScheduleFormMedias));
  yield takeLeading(SAVE_SCHEDULE_FORM_MEDIA, withAlert(saveScheduleFormMedia));
  yield takeEvery(REMOVE_SCHEDULE_FORM_MEDIA, withAlert(removeScheduleFormMedia));
  yield throttle(500, FETCH_COLLECT_NURSES, withAlert(fetchCollectNurses));
  yield throttle(500, FETCH_CALENDAR_SCHEDULES, withAlert(fetchCalenderSchedules));
  yield takeLeading(REORDER_SCHEDULE, withAlert(reorderSchedule));
}
