import { useMemo } from 'react';

import {
  useCurrentSchedule,
  useScheduleUpdates,
  ID,
  DATE,
  DAYS,
  SHIFTS,
  REGISTRATION_DATE,
  SUB_TEAM_MEMBERS,
} from 'store/planning';

import { getRegistrationDate } from './utils';

export const useFormData = () => {
  const [id, date, shifts, regDate, members] = useCurrentSchedule([ID, DATE, SHIFTS, REGISTRATION_DATE, SUB_TEAM_MEMBERS]);
  const [updatedDate, days, updatedShifts, updatedRegDate, updatedMembers] = useScheduleUpdates(id, [
    DATE,
    DAYS,
    SHIFTS,
    REGISTRATION_DATE,
    SUB_TEAM_MEMBERS,
  ]);
  const values = useMemo(
    () => ({
      [DATE]: updatedDate || date,
      [DAYS]: days || shifts?.length || '',
      [SHIFTS]: updatedShifts || shifts || [],
      [REGISTRATION_DATE]: updatedRegDate || regDate || getRegistrationDate(shifts),
      [SUB_TEAM_MEMBERS]: updatedMembers || members || [],
    }),
    [date, days, members, regDate, shifts, updatedDate, updatedMembers, updatedRegDate, updatedShifts]
  );

  return { id, values };
};
