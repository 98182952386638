import React, { useMemo, useCallback, useEffect } from 'react';
import { useField, useFormikContext } from 'formik';
import { pipe, head, last, prop, uniq, sort, map } from 'ramda';
import PropTypes from 'prop-types';

import { BIRTH, DATE, FIRST_NAME, LAST_NAME, GENDER } from 'store/patients';
import { WARD } from 'store/planning';
import { useMedicalHistoryActions, useAlerts, SUB_PATIENT, FIELDS, DIABETES } from 'store/medicalHistory';
import TextInput from 'components/TextInputNew';
import Select from 'components/SelectNew';
import Radios from 'components/RadiosNew';
import Spinner from 'components/FullScreenSpinner';
import { parseDate, parseTime } from 'utils';
import { GENDER_RADIOS } from 'utils/constants';
import DateInput from '../../../DateInput';
import { DIABETES_RADIOS } from '../utils';
import ReverseButton from '../ReverseButton';

import TimeInput from './TimeInput';
import { Inputs, InputWrap, Message } from './styles';

const Patient = ({ patientId, shifts, isMatched }) => {
  const [{ value: birth }, , { setValue: setBirth }] = useField(BIRTH);
  const [{ value: date }, , { setValue: setDate }] = useField(DATE);
  const [{ value: gender }, , { setValue: setGender }] = useField(GENDER);
  const [{ value: diabetes }, , { setValue: setDiabetes }] = useField(DIABETES);
  const { getFieldProps, values, setValues, errors } = useFormikContext();
  const { fetchCollectMedicalHistory } = useMedicalHistoryActions();
  const { action, loading, success, resetAlerts } = useAlerts(fetchCollectMedicalHistory);
  const options = useMemo(() => {
    const [h, m] = parseTime(date).split(':');

    return pipe(
      uniq,
      sort((a, b) => new Date(a).valueOf() - new Date(b).valueOf()),
      map((id) => ({ id, label: parseDate(id) }))
    )([
      date,
      ...shifts.map(([d]) => {
        const objDate = new Date(d);

        objDate.setHours(h);
        objDate.setMinutes(m);

        return objDate.toISOString();
      }),
    ]);
  }, [shifts, date]);
  const [minTime, maxTime] = useMemo(() => {
    const comparedDate = parseDate(date);
    const selected = shifts.findIndex(([d]) => parseDate(d) === comparedDate);

    return [
      pipe(selected < 0 ? head : prop(selected), head, parseTime)(shifts),
      pipe(selected < 0 ? last : prop(selected), last, parseTime)(shifts),
    ];
  }, [date, shifts]);
  const reverseNames = useCallback(
    () =>
      setValues({
        ...values,
        [FIRST_NAME]: values[LAST_NAME],
        [LAST_NAME]: values[FIRST_NAME],
      }),
    [setValues, values]
  );

  useEffect(() => {
    if (patientId) action({ [SUB_PATIENT]: patientId, [FIELDS]: [DIABETES] });
  }, [action, patientId]);

  useEffect(() => {
    if (success) {
      setDiabetes(success[DIABETES], false);
      resetAlerts();
    }
  }, [resetAlerts, setDiabetes, success]);

  return loading ? (
    <Spinner height="100px" />
  ) : (
    <>
      <Inputs $withoutButton={isMatched}>
        <TextInput {...getFieldProps(FIRST_NAME)} label="Vorname" error={errors[FIRST_NAME]} readMode={isMatched} />
        <TextInput {...getFieldProps(LAST_NAME)} label="Nachname" error={errors[LAST_NAME]} readMode={isMatched} />
        {!isMatched && <ReverseButton onClick={reverseNames} />}
      </Inputs>
      <InputWrap $label="Geburtsdatum">
        <DateInput value={birth} onChange={setBirth} readMode={isMatched} allowAllDays pastOnly utcDate />
        {errors[BIRTH] && <Message>{errors[BIRTH]}</Message>}
      </InputWrap>
      <Radios value={gender} label="Geschlecht" items={GENDER_RADIOS} setValue={setGender} readMode={isMatched} />
      <TextInput {...getFieldProps(WARD)} label="Station" error={errors[WARD]} readMode={isMatched} />
      <Radios value={diabetes} label="Diabetes" items={DIABETES_RADIOS} setValue={setDiabetes} />
      <InputWrap $label="Untersuch am">
        <Select value={date} options={options} onSelect={setDate} />
        <TimeInput date={date} onChange={setDate} min={minTime} max={maxTime} />
      </InputWrap>
    </>
  );
};

Patient.defaultProps = { isMatched: false };
Patient.propTypes = {
  patientId: PropTypes.string.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string.isRequired).isRequired).isRequired,
  isMatched: PropTypes.bool,
};

export default Patient;
