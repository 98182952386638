import React, { useCallback } from 'react';
import { useFormikContext, useField } from 'formik';

import {
  NAME,
  NO_PATIENT_REPORTS,
  NO_GP_REPORTS,
  NO_OPTICIAN_REPORTS,
  GENDER,
  GENDERS,
  CONSENT,
  CONSENTS,
  PATIENT_NAME,
  PATIENT_NAMES,
  NURSING_HOME_KIND,
  NURSING_HOME_KINDS,
} from 'store/organizations';
import { defineNursingHomeDataByKind } from 'store/organizations/utils';
import Checkbox from 'components/CheckboxNew';
import Radios from 'components/RadiosNew';
import Select from 'components/SelectNew';

import { Wrapper, Label, Text } from './styles';

const ReportsForm = () => {
  const { setValues } = useFormikContext();
  const [{ value: name }] = useField(NAME);
  const [{ value: noPatientReports }, , { setValue: setNoPatientReports }] = useField(NO_PATIENT_REPORTS);
  const [{ value: noGPReports }, , { setValue: setNoGPReports }] = useField(NO_GP_REPORTS);
  const [{ value: noOpticianReports }, , { setValue: setNoOpticianReports }] = useField(NO_OPTICIAN_REPORTS);
  const [{ value: gender }, , { setValue: setGender }] = useField(GENDER);
  const [{ value: consent }, , { setValue: setConsent }] = useField(CONSENT);
  const [{ value: patientName }, , { setValue: setPatientName }] = useField(PATIENT_NAME);
  const [{ value: nursingHomeKind }] = useField(NURSING_HOME_KIND);
  const onSelectNursingHomeKind = useCallback(
    (val) => {
      const kind = Number(val);

      setValues(($) => ({ ...$, [NURSING_HOME_KIND]: kind, ...defineNursingHomeDataByKind(kind) }));
    },
    [setValues]
  );

  return (
    <>
      <Wrapper>
        <Label>Berichte</Label>
        <Checkbox label="Keine Berichte an Patienten senden" selected={noPatientReports} onClick={setNoPatientReports} />
        <div />
        <Checkbox label="Wünscht keine Berichte an Hausärzte" selected={noGPReports} onClick={setNoGPReports} />
        <div />
        <Checkbox label="Wünscht keine Berichte an Optiker" selected={noOpticianReports} onClick={setNoOpticianReports} />
      </Wrapper>
      <Radios value={gender} label="Artikel" items={GENDERS} setValue={setGender}>
        <Text>{name}</Text>
      </Radios>
      <Select label="Patienten ansprechen als" value={patientName} options={PATIENT_NAMES} onSelect={setPatientName} />
      <Select
        label="Art der Institution"
        value={nursingHomeKind}
        options={NURSING_HOME_KINDS}
        onSelect={onSelectNursingHomeKind}
      />
      <Select label="Einverständniserklärung" value={consent} options={CONSENTS} onSelect={setConsent} />
    </>
  );
};

export default ReportsForm;
