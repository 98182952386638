import React, { useCallback } from 'react';

import { useInitSchedules, usePlanningActions, CURSOR, COUNT, LIMIT, HAS_MORE } from 'store/planning';
import { usePermissions, PLANNING } from 'permissions';
import Input from 'components/TextInputWithSelect';
import Table from 'components/Table';
import Message from 'components/Message';
import Spinner from 'components/FullScreenSpinner';

import { STATUS_OPTIONS, TABLE_HEAD } from './consts';
import Calendar from './Calendar';
import Row from './Row';
import { Container, Wrapper } from './styles';

const List = () => {
  const { updateStatus, updateSearch, updateSort, updatePagination } = usePlanningActions();
  const can = usePermissions();
  const { list, loading, error, status, search, sort, pagination } = useInitSchedules(can.read(PLANNING));
  const renderRow = useCallback((data) => <Row {...data} />, []);

  if (!list) return <Spinner height="calc(100vh - 200px)" />;

  return (
    <Container>
      <Calendar />
      <Wrapper>
        <Input
          selectValue={String(status)}
          options={STATUS_OPTIONS}
          onSelect={updateStatus}
          selectPlaceholder="Status"
          value={search}
          onChange={updateSearch}
          placeholder="Suchen"
        />
        {error && (
          <Message type="error" small={1} form={1} textAlign="right">
            {error}
          </Message>
        )}
      </Wrapper>
      <Wrapper>
        <Table
          title="Terminpläne"
          head={TABLE_HEAD}
          list={list || []}
          renderRow={renderRow}
          sort={sort}
          cursor={pagination[CURSOR]}
          count={pagination[COUNT]}
          limit={pagination[LIMIT]}
          hasMore={pagination[HAS_MORE]}
          updateSort={updateSort}
          updatePagination={updatePagination}
          loading={loading}
        />
      </Wrapper>
    </Container>
  );
};

export default List;
